import styled from 'styled-components'
import { FlexCol, FlexRow } from 'styles/styled'
import { colors, screenSizes } from 'styles/theme'
import ProfileBg from 'public/assets/landing/profileBg.svg'
import { MainContainer } from 'shared/components/cards/style'
import { MobileBenefitsSectionCont } from '../style'

// ================== Info-Card ==================

export const InfoCardContainer = styled.div`
  width: max-content;

  overflow: hidden;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  display: none;

  @media (min-width: ${screenSizes.M}px) {
    padding: 0;
  }
  @media (min-width: 1080px) {
    display: block;
  }
`

export const CardContainer = styled(FlexRow)`
  flex-direction: column;

  @media (min-width: ${screenSizes.M}px) {
    flex-direction: row;
  }

  @media (min-width: ${screenSizes.XLL}px) {
    gap: 56px;
  }
`

export const ProgressCont = styled(FlexCol)`
  flex-direction: row;
  @media (min-width: 1080px) {
    flex-direction: column;
    height: 100%;
    width: auto;
    justify-content: space-between;
  }
`

export const ProgressWrap = styled(FlexRow)`
  flex-direction: column-reverse;
  gap: 36px;
  height: fit-content;

  @media (min-width: 1080px) {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  @media (min-width: ${screenSizes.L}px) {
    gap: 40px;
  }
  @media (min-width: ${screenSizes.XL}px) {
    gap: 60px;
  }
`

export const ProgressDiv = styled.div<{ isActive?: boolean }>`
  width: 80px;
  height: 4px;
  background: ${(props) =>
    props.isActive ? colors.primary : 'rgba(217, 217, 217, 0.4)'};
  border-radius: 20px;

  @media (min-width: 1080px) {
    width: 4px;
    height: 80px;
  }
`

export const TextContainer = styled(FlexCol)`
  p {
    font-family: var(--font-dm-mono);
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    white-space: normal;
  }

  .title {
    font-family: var(--font-tomorrow);
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
    line-height: 40px;
  }

  max-width: 600px;
  @media (min-width: ${screenSizes.M}px) {
    .title {
      font-size: 40px;
    }
  }
`

export const ListContainer = styled.div`
  padding-left: 10px;

  ul {
    list-style: none;
    text-align: left;

    font-family: var(--font-dm-mono);
    font-weight: 500;
    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      &::before {
        content: '\2022';
        color: red;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -2em;
        margin-right: 10px;
      }
    }
  }

  ul li::before {
    content: '\2022';
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -2em;
    margin-right: 10px;
    font-family: var(--font-dm-mono);
    font-weight: 500;
  }
`

export const ImageContainer = styled.div`
  display: none;
  img {
    width: 350px;
    height: 250px;
  }

  @media (min-width: 1080px) {
    display: block;
  }

  @media (min-width: ${screenSizes.XL}px) {
    img {
      height: 400px;
      width: 560px;
    }
  }
`

// ================ Benefits-Card ================

export const MobileBenefitsCont = styled.div`
  .icon {
    transform: translateY(-50%);
    margin-left: auto;
  }

  ${MainContainer} {
    margin: 0 1px;
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
`

export const BenefitsCardCont = styled.div`
  width: 300px;

  h1 {
    font-family: var(--font-tomorrow);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    font-family: var(--font-dm-mono);
    text-align: center;
    line-height: 24px;
  }

  img {
    height: 154px;
  }

  &.mobile {
    width: 100%;
  }

  @media (min-width: 1080px) {
    height: 570px;
  }
`

// ============ Profile-Card ============

export const ProfileCardCont = styled.div`
  padding: 40px 0 0 0;

  & > div {
    gap: 24px;
  }

  h4 {
    font-family: var(--font-tomorrow);
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    font-family: var(--font-dm-mono);
    color: #ffffff;
    text-align: center;
    font-size: 14px;
  }

  @media (min-width: ${screenSizes.M}px) {
    padding: 31px 0;
    & > div {
      gap: 63px;
    }

    h4 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`

export const ImageCont = styled.div`
  overflow: hidden;
  padding: 10px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (min-width: ${screenSizes.XS}px) {
    img {
      width: 150px;
      height: 150px;
    }
  }

  @media (min-width: ${screenSizes.M}px) {
    background: url(${ProfileBg?.src}) no-repeat center;
    img {
      width: 220px;
      height: 220px;
    }
  }
`

export const MobileInfoCont = styled.div`
  display: block-size;

  @media (min-width: 1080px) {
    display: none;
  }

  .slick-dots {
    li {
      height: 4px;
      width: 40px;
      border-radius: 20px;
      background: rgba(217, 217, 217, 0.4);
      margin-right: 16px;
    }

    .slick-active {
      background: #2393b8;
    }
  }
`
export const CarousalDiv = styled(MobileBenefitsSectionCont)`
  position: relative;
  padding: 70px 0 50px 0;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  h1 {
    font-size: 26px;
    font-family: var(--font-tomorrow);
    font-weight: 700;
    text-transform: uppercase;
  }

  .slick-arrow {
    height: 30px;

    &:hover {
      filter: brightness(75%);
    }
  }

  .slick-slider {
    width: 90%;
    margin: 0 auto;
  }
  .slick-prev {
    transition: 0.5s all ease-in-out;
    top: 30%;
    opacity: 0;
  }
  .slick-next {
    transition: 0.5s all ease-in-out;
    top: 32%;
    /* right: 2%; */
    opacity: 0;
  }

  &:hover {
    .slick-prev {
      opacity: 1;
    }
    .slick-next {
      opacity: 1;
    }
  }

  gap: 30px;
  @media (min-width: 1080px) {
    .slick-next {
      top: 32%;
      right: 2%;
    }

    .slick-slider {
      width: 70%;
    }
  }
`

export const CarousalImgCont = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;

  border: 1px solid ${colors?.lightWhite};

  &::before {
    content: '';
    height: 70px;
    width: 70px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    position: absolute;
    border: 2px solid #2393b8;
    border-right: none;
    border-bottom: none;
  }
  &::after {
    content: '';
    height: 70px;
    width: 70px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    position: absolute;
    border: 2px solid #2393b8;
    border-left: none;
    border-top: none;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }
`

export const CarousalTextContainer = styled.div`
  @media (min-width: 1080px) {
    flex-direction: row;
    width: 100%;
  }

  & > div {
    margin: 0 auto;
  }

  p {
    width: 100%;
    white-space: normal;
    margin-top: 16px;
    padding: 0 60px;
    text-align: center;
  }
`
