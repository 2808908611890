import { GridContainer, IGridContainerProps } from './style'

interface GridProps extends IGridContainerProps {
  children?: React.ReactNode
  gridStyle?: any
  className?: string
}
const Grid = (props: GridProps) => {
  const { gridStyle = {}, children, ...otherProps } = props
  return (
    <GridContainer style={gridStyle} {...otherProps}>
      {children}
    </GridContainer>
  )
}

export default Grid
