import Image, { StaticImageData } from 'next/image'

import * as Styles from './style'
import { FlexCol } from 'styles/styled'

export interface IProfileCardData {
  img: StaticImageData
  name: string
  designation: string
}

export interface IProfileCardProps {
  data: IProfileCardData
}

const ProfileCard = (props: IProfileCardProps) => {
  const { data } = props
  return (
    <Styles.ProfileCardCont>
      <FlexCol alignItems='center'>
        <Styles.ImageCont>
          <Image src={data?.img} alt='profile' />
        </Styles.ImageCont>
        <FlexCol gap='12px' alignItems='center'>
          <h4>{data?.name}</h4>
          <p>{data?.designation}</p>
        </FlexCol>
      </FlexCol>
    </Styles.ProfileCardCont>
  )
}

export default ProfileCard
