import styled from 'styled-components'

import { screenSizes } from 'styles/theme'
import { ImageCont } from '../cards/style'

export interface IGridContainerProps {
  gridItemMinWidth?: string
  gridItemMaxWidth?: string
  gridGap?: string
  rowGap?: string
  colGap?: string
}
export const GridContainer = styled.div<IGridContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      ${(props) => props.gridItemMinWidth || '200px'},
      ${(props) => props.gridItemMaxWidth || '1fr'}
    )
  );
  grid-gap: ${(props) => props.gridGap || '10px'};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.colGap};

  &.cardGrid {
    @media (max-width: ${screenSizes.XLL}px) {
      grid-template-columns: repeat(auto-fill, minmax(233px, 1fr));
    }

    @media (max-width: ${screenSizes.XS}px) {
      & > div {
        max-width: 178px;
      }
      margin: 0 auto;
      margin-top: 16px;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
    }

    @media (max-width: 428px) {
      column-gap: 10px;
    }

    @media (max-width: 360px) {
      grid-template-columns: 1fr;

      & > div {
        margin: 0 auto;
      }
    }
  }

  @media (max-width: ${screenSizes.XLL}px) {
    grid-template-columns: repeat(auto-fill, minmax(233px, 1fr));
  }

  &.team-grid {
    grid-template-columns: 1fr;

    @media (min-width: 360px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.select-nft {
    ${ImageCont} {
      height: 120px;
      width: 140px;

      img {
        clip-path: polygon(0 0, 75% 0%, 100% 25%, 100% 100%, 0 100%, 0% 50%);
      }
    }
  }
`
