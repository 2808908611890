import Image from 'next/image'

import { ImageCont, TeamSectionCont } from './style'
import { FlexCol, FlexRow } from 'styles/styled'
import SpaceShip from 'public/assets/landing/space.svg'
import ProfileCard, { IProfileCardData } from './components/ProfileCard'

import IssacImg from 'public/assets/landing/teams/Isaac.jpg'
import CourtneyImg from 'public/assets/landing/teams/courtney.png'
import JediImg from 'public/assets/landing/teams/jedi.jpg'
import MarcusImg from 'public/assets/landing/teams/marcus.jpeg'
import WillImg from 'public/assets/landing/teams/will.jpg'
import NickImg from 'public/assets/landing/teams/nick.jpg'
import Grid from 'shared/components/grid'

const teamData = [
  {
    img: IssacImg,
    name: 'Isaac Ogle',
    designation: 'Co-Founder & Chief Manager',
  },
  {
    img: NickImg,
    name: 'Nicholas NG',
    designation: 'Co-Founder & Chief of Technology',
  },
  {
    img: MarcusImg,
    name: 'Marcus Kenny',
    designation: 'Co-Founder & Technical Advisor',
  },
  {
    img: WillImg,
    name: 'Will Lapides',
    designation: 'Co-Founder & Technical Advisor',
  },
  {
    img: JediImg,
    name: 'Jedi',
    designation: 'Blockchain Technical Advisor',
  },
  {
    img: CourtneyImg,
    name: 'Courtney Ogle',
    designation: 'Artist',
  },
]
const TeamSection = () => {
  return (
    <TeamSectionCont id='Team'>
      <FlexRow alignItems='flex-start'>
        <FlexCol>
          <FlexCol gap='20px'>
            <h1>meet our team</h1>
            <p>
              The VTR team is revolutionizing the way the real estate industry
              works online! The design of the platform is purposely themed and
              gamified to be very immersive and experiential. Don’t be surprised
              if you see someone on an Xbox, Playstation, or a Tesla touchscreen
              working on real estate deals in the near future. We thank you for
              your support in our mission to make real estate fun!
            </p>
          </FlexCol>
          <ImageCont>
            <Image src={SpaceShip} alt='space-ship' />
          </ImageCont>
        </FlexCol>

        <Grid className='team-grid'>
          {teamData?.map((item: IProfileCardData) => (
            <ProfileCard data={item} key={item.name} />
          ))}
        </Grid>
      </FlexRow>
    </TeamSectionCont>
  )
}

export default TeamSection
